import { IconButton, makeStyles, Menu, TableCell } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React from "react";
import { EditUser } from "./edit-user";
import { RemoveUser } from "./remove-user";

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    padding: "3px",
  },
  smalMenu: {
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "8px",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

    outline: "px",
    borderColor: "#212b36",
  },
}));

export const User = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableCell>{user?.fullName}</TableCell>
      <TableCell>{user?.emailAddress}</TableCell>
      <TableCell>{user?.shop?.shopName || "Not available"}</TableCell>
      <TableCell>{user?.role?.roleName}</TableCell>
      <TableCell>
        <IconButton
          className={classes.iconBtn}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.smalMenu}
          elevation={0}
        >
          <RemoveUser user={user?.id} handleCloseMenu={handleClose} />
          <EditUser user={user} handleCloseMenu={handleClose} />
        </Menu>
      </TableCell>
    </>
  );
};
