import { IconButton, Menu, TableCell, makeStyles } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import React from "react";
import { EditTenants } from "./edit-tenant";
import { RemoveTenant } from "./remove-tenant";

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    padding: "3px",
  },
  smalMenu: {
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "8px",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

    outline: "px",
    borderColor: "#212b36",
  },
}));

export const TenantBody = ({ user }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <TableCell>{user?.fullName}</TableCell>
      <TableCell>{user?.emailAddress}</TableCell>
      <TableCell>{user?.phoneNumber}</TableCell>
      <TableCell>{user?.shop?.shopName}</TableCell>
      <TableCell>
        {user?.address?.houseNumber} {user?.address?.street}
      </TableCell>
      <TableCell>{user?.enterpriseName}</TableCell>
      <TableCell>{user?.businessType?.businessName}</TableCell>
      <TableCell>
        <IconButton
          className={classes.iconBtn}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.smalMenu}
          elevation={0}
        >
          <RemoveTenant tenant={user.id} handleCloseMenu={handleClose} />
          <EditTenants tenant={user} handleCloseMenu={handleClose} />
        </Menu>
      </TableCell>
    </>
  );
};
