import React from "react";
import "./card.css";

export const CardCustom = ({ color, bgColor, title, subtitle }) => {
  return (
    <div
      className="cardCustom"
      style={{ color: color, backgroundColor: bgColor }}
    >
      <div className="cardBody">
        <h3>{title}</h3>
        <h6>{subtitle}</h6>
      </div>
    </div>
  );
};
