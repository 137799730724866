import React from "react";
import { Bar } from "react-chartjs-2";
import { useAppContext } from "../../../services/data.context";

export const BarChart = () => {
  const { shops, tenants, users, roles, rents } = useAppContext();
  const state = {
    labels: ["Shops", "Tenants", "Users", "Roles", "Rents"],
    datasets: [
      {
        label: "Nana Data",
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
        ],
        borderColor: [
          "rgb(255, 99, 132)",
          "rgb(255, 159, 64)",
          "rgb(255, 205, 86)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
        ],
        borderWidth: 2,
        data: [
          shops.length,
          tenants.length,
          users.length,
          roles.length,
          rents.length,
        ],
      },
    ],
  };
  return (
    <div>
      <Bar
        data={state}
        options={{
          title: {
            display: true,
            text: "Average Revenue per month",
            fontSize: 20,
          },
          legend: {
            display: true,
            position: "right",
          },
        }}
      />
    </div>
  );
};
