import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BASEURL } from "../../../services/api";
import axios from "axios";
import tokenService from "../../../services/tokenService";
import {
  Breadcrumbs,
  Typography,
  makeStyles,
  Grid,
  Chip,
  Card,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
  Box,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import Skeleton from "react-loading-skeleton";
import shopImg from "../../../assets/shop.svg";
import { formatter } from "../../../services/numberFormatter";

const useStyles = makeStyles((theme) => ({
  backLink: {
    textDecoration: "none",
    color: "#212b36",
  },
  shopWrapper: {
    // background: "#fff",
    // boxShadow: "0px 14px 60px rgba(0,0,0,0.06)",
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(33, 43, 54)",
    transition: " box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "16px",
    zIndex: 0,
    padding: "40px 0px",
    marginTop: "2em",
    marginBottom: "2em",
  },
  shopTitle: {
    fontWeight: 700,
    color: "#212b36",
    lineHeight: "36px",
    fontSize: 24,
    marginBottom: ".3em",
  },
  shopName: {
    color: "#919eab",
  },
  imgContainer: {
    width: "100%",
    objectFit: "contain",
    // background: "#fafafa",
  },
  media: {
    // height: "200px",
    width: "100%",
    objectFit: "contain",
  },
  shopTitle222: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#212b36",
    margin: "30px 0",
  },
  price: {
    fontWeight: 700,
    fontSize: "24x",
    lineHeight: "36px",
    color: "#212b36",
  },
  tableHeader: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.1px",
    color: "#878aa0",
    borderBottom: "1px solid #f1f1f5",
    paddingTop: "0",
  },

  bigCard: {
    padding: "25px 30px",
    minHeight: "100%",
    background: "#ffffff",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "16px",
    zIndex: 0,
  },
  tableTitle: {
    fontWeight: 500,
    color: "#919eab",
    lineHeight: "36px",
    fontSize: 16,
    marginBottom: ".3em",
  },
}));

export const ViewShop = () => {
  const [shop, setShop] = useState(null);
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const token = tokenService.getToken();
  const classes = useStyles();

  useEffect(() => {
    const fetchShop = async () => {
      try {
        const res = await axios.get(`${BASEURL}/shop/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setShop(res?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchShop();
  }, [id, token]);

  return (
    <div className="content">
      <Typography className={classes.shopTitle} variant="h3">
        Shops
      </Typography>

      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/shops" className={classes.backLink}>
          All Shops
        </Link>
        {shop ? (
          <Typography color="textPrimary" className={classes.shopName}>
            {shop.shopName}
          </Typography>
        ) : (
          <Skeleton height={20} width={50} />
        )}
      </Breadcrumbs>

      <div className={classes.shopWrapper}>
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={4}>
            <figure className={classes.imgContainer}>
              <img src={shopImg} alt="shop" className={classes.media} />
            </figure>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            direction="column"
            justifyContent="space-between"
          >
            <Chip
              variant="outlined"
              size="small"
              label={shop?.status}
              style={{
                color: shop?.status ? "#00ab55" : "#878aa0",
                borderColor: shop?.status ? "#00ab55" : "#878aa0",
              }}
            />

            <Typography className={classes.shopTitle222}>
              {shop ? shop.shopName : <Skeleton height={20} width={100} />}
            </Typography>

            <Typography className={classes.price}>
              {shop ? (
                formatter.format(shop.pricePerSqm)
              ) : (
                <Skeleton height={20} width={150} />
              )}
            </Typography>

            <Typography className={classes.shopTitle222}>
              Size: {shop ? shop.size : <Skeleton height={20} width={100} />}
            </Typography>

            <Typography className={classes.shopTitle222}>
              Floor: {shop ? shop.floor : <Skeleton height={20} width={100} />}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <Grid container>
        <Grid item xs={12} sm={12}>
          <Card className={classes.bigCard}>
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="collapsible table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}></TableCell>
                    <TableCell className={classes.tableHeader}>
                      Facility Type
                    </TableCell>
                    <TableCell className={classes.tableHeader}>
                      Number of Facilties
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shop?.facilities.map((facility, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                          >
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        </TableCell>
                        <TableCell>{facility?.facilityType}</TableCell>
                        <TableCell>{facility?.numberOfFacility}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                              <Typography
                                gutterBottom
                                color="textPrimary"
                                className={classes.tableTitle}
                              >
                                Facility Details
                              </Typography>
                              <Table size="small" aria-label="facility details">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className={classes.tableHeader}>
                                      Name of Accessory
                                    </TableCell>
                                    <TableCell className={classes.tableHeader}>
                                      Number of Accessory(s)
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {facility?.facilityDetails?.map(
                                    (details, index) => (
                                      <TableRow key={index}>
                                        <TableCell>
                                          {details?.nameOfAccessory}
                                        </TableCell>
                                        <TableCell>
                                          {details?.numberOfAccessory}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
