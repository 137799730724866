import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { BASEURL } from "./api";
import tokenService from "./tokenService";

const DataContext = createContext();

const DataContextProvider = ({ children }) => {
  const [shops, setShops] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [maintenance, setMaintenance] = useState([]);
  const [maintenanceTypes, setMaintenanceTypes] = useState([]);
  const [users, setAllUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [businessType, setBusinessType] = useState([]);
  const [rents, setRents] = useState([]);
  const [docTypes, setDoctypes] = useState([]);
  const [maintenanceStatus, setMaintenanceStatus] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [payments, setPayments] = useState([]);
  const token = tokenService.getToken();

  const fetchAllShops = async () => {
    try {
      const res = await axios.get(`${BASEURL}/shop/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setShops(res?.data?.data);
    } catch (error) {
      console.log(error?.response);
    }
  };
  useEffect(() => {
    fetchAllShops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllTenants = async () => {
    try {
      const res = await axios.get(`${BASEURL}/tenant/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTenants(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllTenants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllMaintenance = async () => {
    try {
      const res = await axios.get(`${BASEURL}/maintenance/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMaintenance(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllMaintenance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllUsers = async () => {
    try {
      const res = await axios.get(`${BASEURL}/user/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllUsers(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllMaintenanceTypes = async () => {
    try {
      const res = await axios.get(`${BASEURL}/maintenance-type/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMaintenanceTypes(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllMaintenanceTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllRoles = async () => {
    try {
      const res = await axios.get(`${BASEURL}/role/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRoles(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllBusinessTypes = async () => {
    try {
      const res = await axios.get(`${BASEURL}/business-type/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBusinessType(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllBusinessTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllRents = async () => {
    try {
      const res = await axios.get(`${BASEURL}/rent/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRents(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllRents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllMaintenanceStatus = async () => {
    try {
      const res = await axios.get(`${BASEURL}/maintenance-status/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMaintenanceStatus(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllMaintenanceStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllDocTypes = async () => {
    try {
      const res = await axios.get(`${BASEURL}/document-type/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDoctypes(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllDocTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllPaymentTypes = async () => {
    try {
      const res = await axios.get(`${BASEURL}/payment-type/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPaymentTypes(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllPaymentTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchAllPayments = async () => {
    try {
      const res = await axios.get(`${BASEURL}/payment/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPayments(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <DataContext.Provider
      value={{
        shops,
        fetchAllShops,
        fetchAllTenants,
        tenants,
        maintenance,
        fetchAllMaintenance,
        users,
        fetchAllUsers,
        fetchAllMaintenanceTypes,
        maintenanceTypes,
        fetchAllRoles,
        roles,
        fetchAllBusinessTypes,
        businessType,
        fetchAllRents,
        rents,
        fetchAllMaintenanceStatus,
        maintenanceStatus,
        fetchAllDocTypes,
        docTypes,
        fetchAllPaymentTypes,
        paymentTypes,
        fetchAllPayments,
        payments,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

function useAppContext() {
  const context = React.useContext(DataContext);
  if (context === undefined) {
    throw new Error("useAppContext must be within an AppProvider");
  }
  return context;
}

export { DataContextProvider, useAppContext };
