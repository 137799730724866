import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import axios from "axios";
import { BASEURL } from "../../services/api";
import tokenService from "../../services/tokenService";
import { AddDocFile } from "./documents/components/add-doc";
import { ToastContainer } from "react-toastify";
import { DocBody } from "./documents/components/doc-body";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  userTitle: {
    fontWeight: 700,
    color: "#212b36",
    lineHeight: "36px",
    fontSize: 24,
    marginBottom: "1.2em",
  },

  cards: {
    display: "flex",
    flexWrap: "wrap",
  },
}));
export const Documentation = () => {
  const [data, setData] = useState([...Array(5)]);
  const token = tokenService.getToken();
  const classes = useStyles();
  const user = tokenService.getUser();
  const { role } = user;
  const fetchDocs = async () => {
    try {
      const res = await axios.get(`${BASEURL}/document/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("docs", res.data.data);
      setData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content">
      <ToastContainer />
      <div className={classes.header}>
        <Typography className={classes.userTitle} variant="h3">
          Documents
        </Typography>
        {role === "ADMIN" && <AddDocFile fetchFiles={fetchDocs} />}
      </div>
      <div className={classes.cards}>
        {data?.map((dt, index) => (
          <DocBody dt={dt} index={index} fetchDocs={fetchDocs} />
        ))}
      </div>
    </div>
  );
};
