import React from "react";
import {
  IconButton,
  Menu,
  TableCell,
  makeStyles,
  Chip,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import moment from "moment";
import { EditRent } from "./edit-rent";
import { RemoveRent } from "./remove-rent";
import tokenService from "../../../services/tokenService";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.1px",
    color: "#878aa0",
    borderBottom: "1px solid #f1f1f5",
    paddingTop: "0",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  userTitle: {
    fontWeight: 700,
    color: "#212b36",
    lineHeight: "36px",
    fontSize: 24,
    marginBottom: "1.2em",
  },
  bigCard: {
    padding: "25px 30px",
    minHeight: "100%",
    background: "#ffffff",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "16px",
    zIndex: 0,
  },
  iconBtn: {
    padding: "3px",
  },
  smalMenu: {
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "8px",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

    outline: "px",
    borderColor: "#212b36",
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    color: "#212b36",
  },
  iconN: {
    color: "#637381",
    marginRight: 20,
  },
  menuText: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#637381",
  },
  moreIcons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const RentBody = ({ rent }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const user = tokenService.getUser();
  const { role } = user;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableCell>{moment(rent?.rentStart).format("Do-MM-YYYY")}</TableCell>
      <TableCell>{moment(rent?.rentEnd).format("Do-MM-YYYY")}</TableCell>

      <TableCell>{rent?.tenant?.fullName || "Not available"}</TableCell>
      <TableCell>{rent?.shop?.shopName || "Not available"}</TableCell>
      <TableCell>
        <Chip
          variant="outlined"
          size="small"
          label={rent?.rentStatus}
          style={{
            color: rent?.rentStatus === "ACTIVE" ? "#00ab55" : "#ff8c00 ",
            borderColor: rent?.rentStatus === "ACTIVE" ? "#00ab55" : "#ff8c00 ",
          }}
        />
      </TableCell>

      <TableCell>
        <IconButton
          className={classes.iconBtn}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.smalMenu}
          elevation={0}
        >
          <EditRent rent={rent} handleCloseMenu={handleClose} />
          {role === "ADMIN" && (
            <RemoveRent rent={rent.id} handleCloseMenu={handleClose} />
          )}
        </Menu>
      </TableCell>
    </>
  );
};
