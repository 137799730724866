import React, { useState } from "react";
import {
  TextField,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  CircularProgress,
  makeStyles,
  MenuItem,
  Grid,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useAppContext } from "../../../services/data.context";
import { BASEURL } from "../../../services/api";
import tokenService from "../../../services/tokenService";

const useStyles = makeStyles((theme) => ({
  btn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    outline: " 0px",
    border: " 0px",
    margin: " 0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontWeight: 700,
    lineHeight: 1.71429,
    fontSize: "0.9375rem",
    textTransform: "capitalize",
    minWidth: "64px",
    padding: "8px 22px",
    borderRadius: "8px",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(0, 171, 85)",

    boxShadow: "rgb(0 171 85 / 24%) 0px 8px 16px 0px",
    height: "40px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "rgb(2, 89, 45)",
    },
  },
  input: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(2, 89, 45)",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0, 171, 85)",
    },
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    color: "#212b36",
  },
  iconN: {
    color: "#637381",
    marginRight: 20,
  },
  menuText: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#637381",
  },
}));

export const EditTenants = ({ tenant, handleCloseMenu }) => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const token = tokenService.getToken();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { register, handleSubmit } = useForm();
  const { businessType, shops, fetchAllTenants } = useAppContext();
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
    handleCloseMenu();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const onSubmit = (data) => {
    setLoading(true);
    let address = {
      city: data.city,
      district: data.district,
      houseNumber: data.houseNumber,
      street: data.street,
    };
    let newData = {
      address,
      businessTypeId: data.businessTypeId,
      emailAddress: data.emailAddress,
      fullName: data.fullName,
      phoneNumber: data.phoneNumber,
      shopId: data.shopId,
      tenantId: tenant.id,
      enterpriseName: data.enterpriseName,
    };
    axios
      .put(`${BASEURL}/tenant/update`, newData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        fetchAllTenants();
        toast.success("Tenant updated successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.error, {
          position: toast.POSITION.TOP_CENTER,
        });
        setErr(true);
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErr(false);
        }, 2000);
      });
  };

  return (
    <div className="">
      <MenuItem onClick={handleClickOpen}>
        <Edit className={classes.iconN} />{" "}
        <Typography className={classes.menuText}>Edit</Typography>
      </MenuItem>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Edit Tenant"}</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <div>
              <label>Full Name</label>
              <TextField
                name="fullName"
                variant="outlined"
                fullWidth
                className={classes.input}
                defaultValue={tenant?.fullName}
                error={err}
                type="text"
                {...register("fullName")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              />
            </div>
            <div>
              <label>Email Address</label>
              <TextField
                name="emailAddress"
                variant="outlined"
                fullWidth
                className={classes.input}
                defaultValue={tenant?.emailAddress}
                error={err}
                type="email"
                {...register("emailAddress")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              />
            </div>
            <div>
              <label>Phone Number</label>
              <TextField
                name="phoneNumber"
                variant="outlined"
                fullWidth
                className={classes.input}
                defaultValue={tenant?.phoneNumber}
                error={err}
                type="phone"
                {...register("phoneNumber")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              />
            </div>
            <div>
              <label>Enterprise Name</label>
              <TextField
                name="enterpriseName"
                variant="outlined"
                fullWidth
                className={classes.input}
                defaultValue={tenant?.enterpriseName}
                error={err}
                type="text"
                {...register("enterpriseName")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              />
            </div>
            <div>
              <label>Shop</label>
              <TextField
                name="shopId"
                variant="outlined"
                fullWidth
                className={classes.input}
                required
                defaultValue={tenant?.shop?.id}
                select
                error={err}
                type="text"
                {...register("shopId")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {shops?.map((shop, index) => (
                  <MenuItem value={shop?.id} key={index}>
                    {shop?.shopName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <label>Business Type</label>
              <TextField
                name="businessTypeId"
                variant="outlined"
                fullWidth
                className={classes.input}
                required
                select
                defaultValue={tenant?.businessType?.id}
                error={err}
                type="text"
                {...register("businessTypeId")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {businessType?.map((business, index) => (
                  <MenuItem value={business?.id} key={index}>
                    {business?.businessName}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <label>Address</label>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">City</Typography>
                  <TextField
                    name="city"
                    variant="outlined"
                    fullWidth
                    multiline
                    className={classes.input}
                    defaultValue={tenant?.address?.city}
                    error={err}
                    type="text"
                    {...register("city")}
                    style={{
                      marginBottom: "20px",
                      marginRight: "10px",
                      marginTop: 10,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">District</Typography>
                  <TextField
                    name="district"
                    variant="outlined"
                    fullWidth
                    multiline
                    className={classes.input}
                    defaultValue={tenant?.address?.district}
                    error={err}
                    type="text"
                    {...register("district")}
                    style={{
                      marginBottom: "20px",
                      marginRight: "10px",
                      marginTop: 10,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">House Number</Typography>
                  <TextField
                    name="houseNumber"
                    variant="outlined"
                    fullWidth
                    multiline
                    className={classes.input}
                    defaultValue={tenant?.address?.houseNumber}
                    error={err}
                    type="text"
                    {...register("houseNumber")}
                    style={{
                      marginBottom: "20px",
                      marginRight: "10px",
                      marginTop: 10,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">Street</Typography>
                  <TextField
                    name="street"
                    variant="outlined"
                    fullWidth
                    multiline
                    className={classes.input}
                    defaultValue={tenant?.address?.street}
                    error={err}
                    type="text"
                    {...register("street")}
                    style={{
                      marginBottom: "20px",
                      marginRight: "10px",
                      marginTop: 10,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              className={classes.btn}
              disabled={loading}
              disableElevation
            >
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} size={20} />
              ) : (
                "Edit"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
