import React, { useState } from "react";
import {
  TextField,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BASEURL } from "../../services/api";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  btn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    outline: " 0px",
    border: " 0px",
    marginBottom: 15,
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontWeight: 700,
    lineHeight: 1.71429,
    fontSize: "0.9375rem",
    textTransform: "capitalize",
    minWidth: "64px",
    padding: "8px 22px",
    borderRadius: "8px",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(0, 171, 85)",

    boxShadow: "rgb(0 171 85 / 24%) 0px 8px 16px 0px",
    height: "40px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "rgb(2, 89, 45)",
    },
  },
  textTitle: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    cursor: "pointer",
    fontSize: "0.9375rem",
    "&:hover": {
      color: "rgb(0, 171, 85)",
    },
  },
  input: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ": {
      borderColor: "rgb(2, 89, 45)",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
      borderColor: "rgb(0, 171, 85)",
    },
  },
}));

export const ForgotPassword = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { register, handleSubmit } = useForm();
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const onSubmit = (data) => {
    setLoading(true);
    axios
      .post(`${BASEURL}/password/forgot-password`, data)
      .then((res) => {
        console.log(res);

        toast.success("Password reset link sent to your email", {
          position: toast.POSITION.TOP_CENTER,
        });
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setErr(true);
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErr(false);
        }, 2000);
      });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.textTitle} onClick={handleClickOpen}>
        Forgot Password ?
      </Typography>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Forgot Password"}
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <div>
              <label>Email Address</label>
              <TextField
                name="username"
                variant="outlined"
                fullWidth
                className={classes.input}
                required
                error={err}
                type="text"
                {...register("username")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              className={classes.btn}
              disabled={loading}
              disableElevation
            >
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} size={20} />
              ) : (
                "Submit"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
