import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Menu,
  Typography,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { MoreVert, Visibility } from "@material-ui/icons";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { EditShop } from "./edit-shop";
import { RemoveShop } from "./remove-shp";
import shopImg from "../../../assets/shop.svg";
import { formatter } from "../../../services/numberFormatter";
import tokenService from "../../../services/tokenService";

const useStyles = makeStyles((theme) => ({
  shopWrapper: {
    // background: "#fff",
    // boxShadow: "0px 14px 60px rgba(0,0,0,0.06)",
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(33, 43, 54)",
    transition: " box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "16px",
    zIndex: 0,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  media: {
    height: "200px",
    width: "100%",
    objectFit: "contain",
  },
  shop: {
    background: "#fafafa",
    padding: "50px",
    height: "220px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  shopContent: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    fontSize: "16px",
    justifyContent: "space-between",
  },
  shopSmall: {
    display: "flex",
    justifyContent: "space-between",
  },
  shopTitle: {
    fontWeight: 700,
    color: "#212b36",
    lineHeight: "36px",
    fontSize: 24,
    marginBottom: "1.2em",
  },
  iconBtn: {
    padding: "3px",
  },
  smalMenu: {
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "8px",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

    outline: "px",
    borderColor: "#212b36",
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    color: "#212b36",
  },
  iconN: {
    color: "#637381",
    marginRight: 20,
  },
  menuText: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#637381",
  },
  noShopText: {
    margin: "0 auto",
  },
}));

export const Shop = ({ dt, index }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const user = tokenService.getUser();
  const { role } = user;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let history = useHistory();

  const goToViewShop = (id) => {
    history.push(`/shops/${id}`);
  };
  return (
    <>
      <Grid item xs={12} sm={4} key={index}>
        <Card className={classes.shopWrapper}>
          {dt ? (
            <CardActionArea className={classes.shop}>
              <CardMedia
                className={classes.media}
                image={shopImg}
                title="shop"
              />
            </CardActionArea>
          ) : (
            <Skeleton />
          )}
          <CardContent className={classes.shopContent}>
            {dt ? (
              <Typography gutterBottom variant="span" component="span">
                {dt.shopName}
              </Typography>
            ) : (
              <Skeleton height={20} width={200} />
            )}
            <div className={classes.shopSmall}>
              {dt ? (
                <Typography>{formatter.format(dt.pricePerSqm)}</Typography>
              ) : (
                <Skeleton height={20} width={300} />
              )}

              <IconButton
                className={classes.iconBtn}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.smalMenu}
                elevation={0}
              >
                {role === "ADMIN" && (
                  <>
                    <RemoveShop shop={dt.id} handleCloseMenu={handleClose} />
                    <EditShop shop={dt} handleCloseMenu={handleClose} />
                  </>
                )}
                <MenuItem
                  onClick={() => goToViewShop(dt.id)}
                  className={classes.menuItem}
                >
                  <Visibility className={classes.iconN} />
                  <Typography className={classes.menuText}>
                    View Shop
                  </Typography>
                </MenuItem>
              </Menu>
            </div>
            {dt ? (
              <Typography variant="caption">Size: {dt.size}</Typography>
            ) : (
              <Skeleton height={10} width={50} />
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
