import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  makeStyles,
  CardMedia,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { CloudDownload, MoreVert } from "@material-ui/icons";
import axios from "axios";
import getFile from "img_file_dbt";
import React, { useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import Skeleton from "react-loading-skeleton";
import { BASEURL } from "../../../../services/api";
import tokenService from "../../../../services/tokenService";
import {
  base64ToArrayBuffer,
  downloadFile,
  viewFile,
} from "../../../../utils/baseArray";
import { reduceWord } from "../../../../utils/reduceWord";
import { RemoveDocument } from "./delete-doc";
import { EditDocFile } from "./edit-doc";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  userTitle: {
    fontWeight: 700,
    color: "#212b36",
    lineHeight: "36px",
    fontSize: 24,
    marginBottom: "1.2em",
  },
  root: {
    maxWidth: 345,
    width: "18%",
    marginRight: "20px",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(33, 43, 54)",
    transition: " box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    overflow: "hidden",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "16px",
    zIndex: 0,
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
    marginBottom: "-10px",
    cursor: "pointer",
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#637381",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#637381",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  iconN: {
    color: "#637381",
    marginRight: theme.spacing(1),
  },
  iconBtn: {
    // padding: "3px",
    fontSize: "12px",
  },
  menuText: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#637381",
    textDecoration: "none",
  },
  menuItem: {
    // display: "flex",
    // justifyContent: "space-between",
    // color: "#212b36",
  },
  cardFooter: {
    display: "flex",
    fontSize: "16px",
    justifyContent: "space-between",
  },
}));

export const DocBody = ({ dt, index, fetchDocs }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);

  const classes = useStyles();
  const token = tokenService.getToken();
  const user = tokenService.getUser();
  const { role } = user;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewImage = () => {
    let newData = {
      documentId: dt?.id,
      documentTypeId: dt?.documentType?.id,
      shopId: dt?.shop?.id,
      tenantId: dt?.tenant?.id,
    };

    axios
      .post(`${BASEURL}/document/view2`, newData, {
        headers: {
          Authorization: `Bearer ${tokenService}`,
          "Content-Type": undefined,
        },
      })

      .then((res) => {
        let imgToArray = base64ToArrayBuffer(res.data.data.byteArray);
        viewFile(imgToArray, res.data.data.fileName);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadImage = () => {
    let newData = {
      documentId: dt?.id,
      documentTypeId: dt?.documentType?.id,
      shopId: dt?.shop?.id,
      tenantId: dt?.tenant?.id,
    };
    setImgLoading(true);
    axios
      .post(`${BASEURL}/document/view2`, newData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": undefined,
        },
      })

      .then((res) => {
        let imgToArray = base64ToArrayBuffer(res.data.data.byteArray);
        downloadFile(imgToArray, res.data.data.fileName);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setImgLoading(false);
      });
  };
  return (
    <Card className={classes.root} elevation={1} key={index}>
      {dt ? (
        <CardHeader
          title={
            <LinesEllipsis
              text={`${dt?.tenant?.fullName}`}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          }
          subheader={
            <LinesEllipsis
              text={`${dt?.tenant?.shop.shopName}`}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          }
          className={classes.subtitle}
        />
      ) : (
        <Skeleton
          height={20}
          width={138}
          style={{ marginLeft: "18px", marginTop: 5 }}
        />
      )}
      <CardContent>
        {dt ? (
          <CardMedia
            className={classes.media}
            title="Folders"
            image={getFile(dt.docName)}
          />
        ) : (
          <Skeleton height={80} style={{ display: "block" }} />
        )}
      </CardContent>
      <CardActions className={classes.cardFooter}>
        <div>
          <Typography className={classes.subtitle}>
            {reduceWord(dt?.docName)}
          </Typography>
        </div>
        <IconButton
          className={classes.iconBtn}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.smalMenu}
          elevation={0}
        >
          {/* <MenuItem className={classes.menuItem} onClick={() => viewImage()}>
            <Visibility className={classes.iconN} />
            <Typography className={classes.menuText}>View</Typography>
          </MenuItem> */}
          <MenuItem
            className={classes.menuItem}
            onClick={() => downloadImage()}
          >
            <CloudDownload className={classes.iconN} />

            {!imgLoading ? (
              <Typography className={classes.menuText}>Download</Typography>
            ) : (
              <CircularProgress color="primary" size={15} />
            )}
          </MenuItem>
          {role === "ADMIN" && (
            <>
              <EditDocFile
                handleCloseMenu={handleClose}
                fetchFiles={fetchDocs}
                doc={dt}
              />
              <RemoveDocument
                doc={dt}
                fetchFiles={fetchDocs}
                handleCloseMenu={handleClose}
              />
            </>
          )}
        </Menu>
      </CardActions>
    </Card>
  );
};
