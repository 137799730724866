import {
  TextField,
  makeStyles,
  Grid,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";
import { useFieldArray } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 40,
  },
  input: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(2, 89, 45)",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0, 171, 85)",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
  },
  btn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    outline: " 0px",
    border: " 2px solid",
    margin: "10px 0px 10px 50px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontWeight: 400,
    lineHeight: 1.71429,
    fontSize: "0.7rem",
    textTransform: "capitalize",
    minWidth: "64px",
    padding: "8px 17px",
    borderRadius: "8px",
    color: "rgb(0,0,0)",
    backgroundColor: "#fff",
    borderColor: "rgb(0, 171, 85)",
    height: "30px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "rgb(0, 171, 85)",
      color: "rgb(255, 255, 255)",
    },
  },
}));
export const NestedfieldArray = ({ nestIndex, control, register }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `facilities[${nestIndex}].facilityDetails`,
  });
  const classes = useStyles();
  return (
    <div>
      <Button
        size="small"
        className={classes.btn}
        onClick={() =>
          append({
            nameOfAccessory: "",
            numberOfAccessory: "",
          })
        }
      >
        Add Details
      </Button>
      {fields.map((item, k) => {
        return (
          <Grid container spacing={2} key={item.id} className={classes.root}>
            <Grid item xs={5} sm={5}>
              <div>
                <label>Name of Accessory</label>
                <TextField
                  name={`facilities[${nestIndex}].facilityDetails[${k}].nameOfAccessory`}
                  variant="outlined"
                  className={classes.input}
                  required
                  type="text"
                  fullWidth
                  size="small"
                  {...register(
                    `facilities[${nestIndex}].facilityDetails[${k}].nameOfAccessory`
                  )}
                  defaultValue={item.nameOfAccessory}
                  style={{
                    marginRight: "10px",
                    marginTop: 10,
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={5} sm={5}>
              <label>Number of Accessory</label>
              <TextField
                name={`facilities[${nestIndex}].facilityDetails[${k}].numberOfAccessory`}
                variant="outlined"
                className={classes.input}
                required
                fullWidth
                size="small"
                {...register(
                  `facilities[${nestIndex}].facilityDetails[${k}].numberOfAccessory`,
                  { valueAsNumber: true }
                )}
                defaultValue={item.numberOfAccessory}
                style={{
                  marginRight: "10px",
                  marginTop: 10,
                }}
              />
            </Grid>

            <Grid item xs={1} sm={1} className={classes.btnContainer}>
              <Tooltip title="Click to remove this field" arrow>
                <IconButton onClick={() => remove(k)}>
                  <Delete color="secondary" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};
