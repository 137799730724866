import React from "react";
import {
  Button,
  Typography,
  makeStyles,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import svgIcon from "../../assets/notfound.svg";
import { Mood } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",

    height: "100vh",
    background: "#fff",
  },

  bigCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: "50px",
    background: "#ffffff",

    transition: "all 0.4s ease-in-out 0s",
  },
  img: {
    width: " 50%",
    marginBottom: 40,
  },
  btn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    outline: " 0px",
    border: " 0px",
    margin: " 0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontWeight: 700,
    lineHeight: 1.71429,
    fontSize: "0.9375rem",
    textTransform: "capitalize",
    minWidth: "64px",
    padding: "8px 22px",
    borderRadius: "8px",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(0, 171, 85)",
    width: "30%",
    boxShadow: "rgb(0 171 85 / 24%) 0px 8px 16px 0px",
    height: "48px",
    marginTop: "1.3em",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "rgb(2, 89, 45)",
    },
  },
  title: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "48px",
    color: "#212b36",
  },
  subtitle: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "48px",
    color: "#212b36",
  },
  icon: {
    color: "#00ab55",
    fontSize: "2rem",
  },
}));
export const NotFound = () => {
  let history = useHistory();
  const classes = useStyles();
  const goBack = () => history.push("/");
  return (
    <div className={classes.root}>
      <Container className={classes.bigCard}>
        <CssBaseline />

        <img src={svgIcon} alt="Not found" className={classes.img} />
        <Typography className={classes.title}>Page does not exist,</Typography>
        <Typography className={classes.subtitle}>
          click the button below to go home
        </Typography>
        <Mood className={classes.icon} />
        <Button className={classes.btn} onClick={goBack}>
          Go Back
        </Button>
      </Container>
    </div>
  );
};
