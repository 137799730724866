import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  overrides: {
    MuiListItem: {
      root: {
        "&$selected": {
          color: "#00ab55",
          borderColor: "rgb(0, 171, 85)",
          background: "rgba(0, 171, 85, 0.08);",
          fontWeight: "bold",
        },
      },
    },
  },
});
