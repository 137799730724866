import jwt_decode from "jwt-decode";
class TokenService {
  getToken() {
    const token = localStorage.getItem("token");
    return token;
  }
  getUser() {
    const token = this.getToken();
    const decoded = jwt_decode(token);
    return decoded;
  }

  setToken(token) {
    localStorage.setItem("token", token);
  }
  removeToken() {
    localStorage.removeItem("token");
  }

  clearStorage() {
    localStorage.clear();
  }
}

export default new TokenService();
