import { Card, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { useAppContext } from "../../services/data.context";
import { BarChart } from "./components/Bar";
import { CardCustom } from "./components/card";
import { DoughnutChart } from "./components/Pie";
import CountUp from "react-countup";
import tokenService from "../../services/tokenService";

const useStyles = makeStyles((theme) => ({
  bigCard: {
    padding: "25px 30px",
    minHeight: "100%",
    background: "#ffffff",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "16px",
    zIndex: 0,
  },
}));
export const Dashboard = () => {
  const classes = useStyles();
  const { shops, tenants, users, roles } = useAppContext();
  const user = tokenService.getUser();
  const { role } = user;
  return (
    <div className="content">
      <h2>Dashboard</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <CardCustom
            color={"rgb(0, 82, 73)"}
            bgColor={"rgb(200, 250, 205)"}
            title={
              <CountUp
                start={0}
                end={shops?.length}
                duration={2.5}
                separator=","
              />
            }
            subtitle="Total Shops"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardCustom
            color={"rgb(4, 41, 122)"}
            bgColor={"rgb(208, 242, 255)"}
            title={
              <CountUp
                start={0}
                end={tenants?.length}
                duration={2.5}
                separator=","
              />
            }
            subtitle="Total Tenants"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardCustom
            color={"rgb(122, 79, 1"}
            bgColor={"rgb(255, 247, 205)"}
            title={
              <CountUp
                start={0}
                end={users?.length}
                duration={2.5}
                separator=","
              />
            }
            subtitle="Total Users"
          />
        </Grid>
        {role !== "TENANT" && (
          <Grid item xs={12} sm={3}>
            <CardCustom
              color={"rgb(122, 12, 46)"}
              bgColor={"rgb(255, 231, 217)"}
              title={
                <CountUp
                  start={0}
                  end={roles?.length}
                  duration={2.5}
                  separator=","
                />
              }
              subtitle="Total Roles"
            />
          </Grid>
        )}
      </Grid>
      <div style={{ marginBottom: 35 }}></div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <Card className={classes.bigCard}>
            <BarChart />
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card className={classes.bigCard}>
            <DoughnutChart />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
