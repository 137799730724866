import React, { useState } from "react";
import {
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Tooltip,
  Typography,
  MenuItem,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.1px",
    color: "#878aa0",
    borderBottom: "1px solid #f1f1f5",
    paddingTop: "0",
  },
  btn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    outline: " 0px",
    border: " 0px",
    margin: " 0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontWeight: 700,
    lineHeight: 1.71429,
    fontSize: "0.9375rem",
    textTransform: "capitalize",
    minWidth: "64px",
    padding: "8px 22px",
    borderRadius: "8px",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(0, 171, 85)",

    boxShadow: "rgb(0 171 85 / 24%) 0px 8px 16px 0px",
    height: "40px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "rgb(2, 89, 45)",
    },
  },
  input: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(2, 89, 45)",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0, 171, 85)",
    },
  },
  iconN: {
    color: "#637381",
    marginRight: 20,
  },
  menuText: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#637381",
  },
  menuItem: {
    display: "flex",

    color: "#212b36",
  },
}));
export const ViewMaintenance = ({ maintenance, handleCloseMenu }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
    handleCloseMenu();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Click to remove this maintenance" arrow>
        <MenuItem onClick={handleClickOpen} className={classes.menuItem}>
          <Visibility className={classes.iconN} />
          <Typography className={classes.menuText}>View </Typography>
        </MenuItem>
      </Tooltip>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Maintenance</DialogTitle>
        <DialogContent>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Shop</TableCell>
                <TableCell className={classes.tableHeader}>Duration</TableCell>

                <TableCell className={classes.tableHeader}>
                  Description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableCell>{maintenance.shop.shopName}</TableCell>
              <TableCell>{maintenance.duration}</TableCell>
              <TableCell>{maintenance.description}</TableCell>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            className={classes.btn}
            onClick={handleClose}
            disableElevation
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
