export const reduceWord = (text) => {
  if (text) {
    let newText = text.split(".");
    let firstText = newText[0].slice(0, 10);
    let newWord = [];
    newWord.push(firstText);
    newWord.push(newText[1]);
    return newWord.join(".");
  }
};
