import React from "react";
import { Avatar, Typography } from "@material-ui/core";
import profileImg from "../../assets/pr.jpg";
import "./headerCard.css";

export const HeaderCard = ({ role, fullName }) => {
  return (
    <div className="headerCard">
      <Avatar alt="profile" src={profileImg} />
      <div className="profileBody">
        <Typography variant="span" className="title">
          {fullName}
        </Typography>
        <Typography variant="span" className="subtitle">
          {role}
        </Typography>
      </div>
    </div>
  );
};
