import {
  Chip,
  IconButton,
  makeStyles,
  Menu,
  TableCell,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

import moment from "moment";
import React, { useState } from "react";
import tokenService from "../../../services/tokenService";

import { EditMaintenanceStatus } from "./edit-maint-status";
import { EditMaintenance } from "./edit-maintenance";
import { RemoveMaintenance } from "./remove-maintenance";
import { ViewMaintenance } from "./view-maintenance";

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    padding: "3px",
  },
  smalMenu: {
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "8px",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

    outline: "px",
    borderColor: "#212b36",
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    color: "#212b36",
  },
  iconN: {
    color: "#637381",
    marginRight: 20,
  },
  menuText: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#637381",
  },
  pendingIcon: {
    color: "orange",
    marginRight: 15,
    borderColor: "orange",
  },
  in_progressIcon: {
    color: "purple",
    marginRight: 15,
    borderColor: "purple",
  },
  resolvedIcon: {
    color: "green",
    marginRight: 15,
    marginTop: 7,
    borderColor: "green",
  },
  iconBtnStatus: {
    padding: "3px",
  },
  tdClassStatus: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const MaintenanceBody = ({ main }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const user = tokenService.getUser();
  const { role } = user;

  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableCell>{main?.shop?.shopName}</TableCell>
      <TableCell>{main?.maintenanceType?.maintenanceType}</TableCell>

      <TableCell>{main?.description}</TableCell>
      <TableCell>{main?.cost || "Not Available"} </TableCell>

      {/* <TableCell>{main?.duration || "Not Available"}</TableCell> */}
      <TableCell className={classes.tdClassStatus}>
        <Chip
          variant="outlined"
          size="small"
          label={main?.maintenanceStatus?.maintenanceStatus}
          className={
            main.maintenanceStatus?.maintenanceStatus === "PENDING"
              ? classes.pendingIcon
              : main.maintenanceStatus?.maintenanceStatus === "IN_PROGRESS"
              ? classes.in_progressIcon
              : classes.resolvedIcon
          }
        />

        {main.maintenanceStatus?.maintenanceStatus !== "RESOLVED" &&
          role !== "TENANT" && <EditMaintenanceStatus maintenance={main} />}
      </TableCell>
      <TableCell>{main?.updatedBy?.fullName || "Not Available"}</TableCell>
      <TableCell>{moment(main?.createdDate).format("Do-MM-YYYY")}</TableCell>

      <TableCell>
        <IconButton
          className={classes.iconBtn}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.smalMenu}
          elevation={0}
        >
          <ViewMaintenance maintenance={main} handleCloseMenu={handleClose} />
          {role === "ADMIN" && (
            <RemoveMaintenance main={main.id} handleCloseMenu={handleClose} />
          )}
          {main.maintenanceStatus?.maintenanceStatus !== "RESOLVED" &&
            role === "ADMIN" && (
              <EditMaintenance
                maintenance={main}
                handleCloseMenu={handleClose}
              />
            )}
        </Menu>
      </TableCell>
    </>
  );
};
