import React, { useState } from "react";
import {
  TextField,
  useTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  CircularProgress,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useAppContext } from "../../../services/data.context";
import tokenService from "../../../services/tokenService";
import { BASEURL } from "../../../services/api";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

const useStyles = makeStyles((theme) => ({
  btn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    outline: " 0px",
    border: " 0px",
    margin: " 0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontWeight: 700,
    lineHeight: 1.71429,
    fontSize: "0.9375rem",
    textTransform: "capitalize",
    minWidth: "64px",
    padding: "8px 22px",
    borderRadius: "8px",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(0, 171, 85)",

    boxShadow: "rgb(0 171 85 / 24%) 0px 8px 16px 0px",
    height: "40px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "rgb(2, 89, 45)",
    },
  },
  input: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(2, 89, 45)",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0, 171, 85)",
    },
  },
}));
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const evidenceType = [
  {
    value: "FILE",
  },
  {
    value: "TEXT",
  },
  {
    value: "BOTH",
  },
];
export const AddPayment = () => {
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const token = tokenService.getToken();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { register, handleSubmit } = useForm();
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const { tenants, fetchAllPayments, paymentTypes } = useAppContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const onSubmit = (data) => {
    let formData = new FormData();
    formData.append("dateOfTransaction", data.dateOfTransaction);
    formData.append("evidence", data.evidence);
    formData.append("evidenceType", data.evidenceType);
    formData.append("narration", data.narration);
    formData.append("paymentTypeId", data.paymentTypeId);
    formData.append("tenantId", data.tenantId);
    file.map(({ file }) => {
      return formData.append("file", file, file.name);
    });
    setLoading(true);
    axios
      .post(`${BASEURL}/payment/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": undefined,
        },
      })
      .then((res) => {
        console.log(res);
        fetchAllPayments();
        toast.success("Payment added successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error, {
          position: toast.POSITION.TOP_CENTER,
        });
        setErr(true);
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setErr(false);
        }, 2000);
      });
  };

  return (
    <div className="">
      <Button className={classes.btn} onClick={handleClickOpen}>
        <AddCircleOutline style={{ marginRight: "5px" }} />
        New Payment
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Add Payment"}</DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <div>
              <label>Payment Type</label>
              <TextField
                name="paymentTypeId"
                variant="outlined"
                fullWidth
                className={classes.input}
                required
                select
                error={err}
                type="text"
                {...register("paymentTypeId")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {paymentTypes?.map((payment, index) => (
                  <MenuItem value={payment?.id} key={index}>
                    {payment?.paymentType}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <label>Tenant</label>
              <TextField
                name="tenantId"
                variant="outlined"
                fullWidth
                className={classes.input}
                required
                select
                error={err}
                type="text"
                {...register("tenantId")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {tenants?.map((tenant, index) => (
                  <MenuItem value={tenant.id} key={index}>
                    {tenant?.fullName}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div>
              <label>Narration</label>
              <TextField
                name="narration"
                variant="outlined"
                fullWidth
                multiline
                className={classes.input}
                required
                error={err}
                type="text"
                {...register("narration")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              />
            </div>
            <div>
              <label>Evidence</label>
              <TextField
                name="evidence"
                variant="outlined"
                fullWidth
                multiline
                className={classes.input}
                required
                error={err}
                type="text"
                {...register("evidence")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              />
            </div>
            <div>
              <label>Evidence Type</label>
              <TextField
                name="evidenceType"
                variant="outlined"
                fullWidth
                className={classes.input}
                required
                select
                error={err}
                type="text"
                {...register("evidenceType")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {evidenceType?.map((evidence, index) => (
                  <MenuItem value={evidence.value} key={index}>
                    {evidence?.value}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              <label>Date Of Transaction</label>
              <TextField
                name="dateOfTransaction"
                variant="outlined"
                fullWidth
                className={classes.input}
                required
                error={err}
                type="date"
                {...register("dateOfTransaction")}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: 10,
                }}
              />
            </div>
            <div>
              <FilePond
                files={file}
                allowReorder={true}
                allowMultiple={false}
                onupdatefiles={setFile}
                name="file"
                labelIdle="Drag & Drop your file"
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              className={classes.btn}
              disabled={loading}
              disableElevation
            >
              {loading ? (
                <CircularProgress style={{ color: "#fff" }} size={20} />
              ) : (
                "Add"
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
