import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useAppContext } from "../../../services/data.context";

export const DoughnutChart = () => {
  const { shops, tenants, users, roles, rents } = useAppContext();
  const state = {
    labels: ["Shops", "Tenants", "Users", "Roles", "Rents"],
    datasets: [
      {
        label: "Shops",
        backgroundColor: [
          "#B21F00",
          "#C9DE00",
          "#2FDE00",
          "#00A6B4",
          "#6800B4",
        ],
        hoverBackgroundColor: [
          "#501800",
          "#4B5000",
          "#175000",
          "#003350",
          "#35014F",
        ],
        data: [
          shops.length,
          tenants.length,
          users.length,
          roles.length,
          rents.length,
        ],
      },
    ],
  };

  return (
    <div>
      <Doughnut
        data={state}
        options={{
          title: {
            display: true,
            text: "Nana Plaza Data",
            fontSize: 20,
          },
          legend: {
            display: true,
            position: "right",
          },
        }}
      />
    </div>
  );
};
