import React from "react";
import { TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  input: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(2, 89, 45)",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0, 171, 85)",
    },
    marginBottom: "2em",
  },
}));

const SearchBox = ({ searchChange, place }) => {
  const classes = useStyles();
  return (
    <div>
      <TextField
        variant="outlined"
        className={classes.input}
        placeholder={place}
        type="search"
        onChange={searchChange}
      />
    </div>
  );
};

export default SearchBox;
