import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "./infrastructure/theme/theme";
import { AppNavigation } from "./infrastructure/navigation";
import { DataContextProvider } from "./services/data.context";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <DataContextProvider>
        <AppNavigation />
      </DataContextProvider>
    </ThemeProvider>
  );
};

export default App;
