import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  makeStyles,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Hidden,
  Collapse,
} from "@material-ui/core";
import { PowerSettingsNew, ExpandLess, ExpandMore } from "@material-ui/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import MenuIcon from "@material-ui/icons/Menu";
import { useLocation } from "react-router-dom";
import { HeaderCard } from "../headerCard";
import tokenService from "../../services/tokenService";
import { RiDashboardLine } from "react-icons/ri";
import { BsShop, BsPeople, BsDot } from "react-icons/bs";
import { TiSpannerOutline } from "react-icons/ti";
import { MdOutlineBusinessCenter, MdOutlinePayment } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { RiUser2Line } from "react-icons/ri";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { GiTakeMyMoney } from "react-icons/gi";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fff",
    color: "#637381",
  },
  ScrollHeight: {
    height: "calc(100vh - 88px)",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },
  header: {
    fontSize: "10px",
    lineHeight: "67px",
    marginLeft: 20,
    marginTop: 10,
    userSelect: "none",
    padding: "2em",
    // backgroundColor: "#2563a4"
  },
  headerText: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "36px",
    color: "rgb(0, 171, 85)",
  },
  img: {
    maxWidth: "97px",
    //   position: "absolute"
  },
  subheader: {
    fontSize: "15px",
    lineHeight: "17px",
    fontWeight: 500,
    display: "block",
    fontStyle: "normal",
    padding: "15px 0",
    paddingLeft: "50px",
    borderRight: "5px solid transparent",
    position: "relative",
    color: "#ffffff",
    zIndex: 1,
    marginTop: 0,
  },
  list: {
    paddingBottom: "60px",
  },
  listItems: {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 500,
    display: "block",
    fontStyle: "normal",
    padding: "15px 0",
    paddingLeft: "58px",
    borderRight: "5px solid transparent",
    position: "relative",
    color: "#637381",
    zIndex: 1,
  },
  listItemMenu: {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
    fontStyle: "normal",
    padding: "15px 0",
    paddingLeft: "58px",
    borderRight: "5px solid transparent",
    position: "relative",
    color: "#637381",
    zIndex: 1,
    cursor: "pointer",
  },
  listItemMenuChildren: {
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: 500,
    display: "block",
    fontStyle: "normal",
    padding: "15px 0",
    paddingLeft: "60px",
    borderRight: "5px solid transparent",
    position: "relative",
    color: "#637381",
    zIndex: 1,
  },
  iconColor: {
    color: "#637381",
    fontSize: "14px",
  },
  topBar: {
    height: "80px",
    backgroundColor: "#ffffff",
    border: "1px solid #f5f6fa",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      backgroundColor: "#ffffff",
    },
  },

  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  search: {
    display: "flex",
    flexGrow: 1,
  },
  searchIcon: {
    marginRight: "10px",
  },
  appIcons: {
    display: "flex",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
  },
  link1: {
    textDecoration: "none",
    color: "#000000",
  },
  listText: {
    // fontSize: "20px",
  },
  listIcons: {
    marginRight: "15px",
    fontSize: "18px",
    position: "relative",
    top: "4px",
  },
}));

const SideBar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const [openMenu, setOpenMenu] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);
  const [openMenu3, setOpenMenu3] = useState(false);

  const user = tokenService.getUser();
  const { role } = user;

  const logout = () => {
    tokenService.clearStorage();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = () => {
    setOpenMenu(!openMenu);
  };
  const handleClick2 = () => {
    setOpenMenu2(!openMenu2);
  };
  const handleClick3 = () => {
    setOpenMenu3(!openMenu3);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <>
      <div className={classes.toolbar} />
      <header className={classes.header}>
        <Typography className={classes.headerText}>Nana Plaza</Typography>
      </header>
      <HeaderCard role={user?.role} fullName={user.fullName} />

      <PerfectScrollbar component="div" className={classes.ScrollHeight}>
        <List className={classes.list}>
          <Link className={classes.link} to="/">
            <ListItem
              button
              className={classes.listItems}
              selected={"/" === pathname}
            >
              <RiDashboardLine className={classes.listIcons} />
              <Typography variant="span">Dashboard</Typography>
            </ListItem>
          </Link>
          {role !== "MAINTENANCE" && (
            <>
              <Link className={classes.link} to="/shops">
                <ListItem
                  button
                  className={classes.listItems}
                  selected={"/shops" === pathname}
                >
                  <BsShop className={classes.listIcons} />
                  <Typography variant="span">Shops</Typography>
                </ListItem>
              </Link>
              <Link className={classes.link} to="/tenants">
                <ListItem
                  button
                  className={classes.listItems}
                  selected={"/tenants" === pathname}
                >
                  <BsPeople className={classes.listIcons} />
                  <Typography variant="span">Tenants</Typography>
                </ListItem>
              </Link>
              {role !== "TENANT" && (
                <Link className={classes.link} to="/rents">
                  <ListItem
                    button
                    className={classes.listItems}
                    selected={"/rents" === pathname}
                  >
                    <GiTakeMyMoney className={classes.listIcons} />
                    <Typography variant="span">Rents</Typography>
                  </ListItem>
                </Link>
              )}
            </>
          )}
          {role !== "MAINTENANCE" && (
            <ListItem onClick={handleClick3} className={classes.listItemMenu}>
              <div>
                <MdOutlinePayment className={classes.listIcons} />
                <Typography variant="span">Payment</Typography>
              </div>

              {openMenu3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          <Collapse in={openMenu3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link className={classes.link} to="/payments">
                <ListItem
                  button
                  className={classes.listItems}
                  selected={"/payments" === pathname}
                >
                  <BsDot className={classes.listIcons} />
                  <Typography variant="span">Payments</Typography>
                </ListItem>
              </Link>
              {role === "ADMIN" && (
                <Link className={classes.link} to="/payment-types">
                  <ListItem
                    button
                    className={classes.listItemMenuChildren}
                    selected={"/payment-types" === pathname}
                  >
                    <BsDot className={classes.listIcons} />
                    <Typography variant="span">Payment Types</Typography>
                  </ListItem>
                </Link>
              )}
            </List>
          </Collapse>
          <ListItem onClick={handleClick} className={classes.listItemMenu}>
            <div>
              <TiSpannerOutline className={classes.listIcons} />
              <Typography variant="span">Maintenance</Typography>
            </div>

            {openMenu ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openMenu} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link className={classes.link} to="/maintenances">
                <ListItem
                  button
                  className={classes.listItemMenuChildren}
                  selected={"/maintenances" === pathname}
                >
                  <BsDot className={classes.listIcons} />
                  <Typography variant="span">Maintenance</Typography>
                </ListItem>
              </Link>
              {role !== "TENANT" && (
                <>
                  <Link className={classes.link} to="/maintenance-types">
                    <ListItem
                      button
                      className={classes.listItemMenuChildren}
                      selected={"/maintenance-types" === pathname}
                    >
                      <BsDot className={classes.listIcons} />
                      <Typography variant="span">Maintenance Types</Typography>
                    </ListItem>
                  </Link>
                  <Link className={classes.link} to="/maintenance-status">
                    <ListItem
                      button
                      className={classes.listItemMenuChildren}
                      selected={"/maintenance-status" === pathname}
                    >
                      <BsDot className={classes.listIcons} />
                      <Typography variant="span">Maintenance Status</Typography>
                    </ListItem>
                  </Link>
                </>
              )}
            </List>
          </Collapse>

          {role !== "MAINTENANCE" && (
            <>
              {role !== "TENANT" && (
                <Link className={classes.link} to="/business-types">
                  <ListItem
                    button
                    className={classes.listItems}
                    selected={"/business-types" === pathname}
                  >
                    <MdOutlineBusinessCenter className={classes.listIcons} />
                    <Typography variant="span">Business Types</Typography>
                  </ListItem>
                </Link>
              )}
              <Link className={classes.link} to="/users">
                <ListItem
                  button
                  className={classes.listItems}
                  selected={"/users" === pathname}
                >
                  <FiUsers className={classes.listIcons} />
                  <Typography variant="span">Users</Typography>
                </ListItem>
              </Link>
              {role !== "TENANT" && (
                <Link className={classes.link} to="/roles">
                  <ListItem
                    button
                    className={classes.listItems}
                    selected={"/roles" === pathname}
                  >
                    <RiUser2Line className={classes.listIcons} />
                    <Typography variant="span">Roles</Typography>
                  </ListItem>
                </Link>
              )}
              <ListItem onClick={handleClick2} className={classes.listItemMenu}>
                <div>
                  <IoDocumentAttachOutline className={classes.listIcons} />
                  <Typography variant="span">Documentation</Typography>
                </div>

                {openMenu2 ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openMenu2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link className={classes.link} to="/documentations">
                    <ListItem
                      button
                      className={classes.listItems}
                      selected={"/documentations" === pathname}
                    >
                      <BsDot className={classes.listIcons} />
                      <Typography variant="span">Documents</Typography>
                    </ListItem>
                  </Link>
                  {role === "ADMIN" && (
                    <Link className={classes.link} to="/document-types">
                      <ListItem
                        button
                        className={classes.listItemMenuChildren}
                        selected={"/document-types" === pathname}
                      >
                        <BsDot className={classes.listIcons} />
                        <Typography variant="span">Document Types</Typography>
                      </ListItem>
                    </Link>
                  )}
                </List>
              </Collapse>
            </>
          )}
        </List>
      </PerfectScrollbar>
    </>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root}>
      <AppBar className={classes.topBar} color="default" elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.search}></div>
          <div />
          <div className={classes.appIcons}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={handleMenu}
            >
              <PowerSettingsNew />
            </IconButton>
            <Menu
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              elevation={0}
            >
              <Link to="/login" onClick={logout} className={classes.link1}>
                <MenuItem>Log out</MenuItem>
              </Link>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <nav>
        <Hidden smUp implementation="css">
          <Drawer
            className={classes.drawer}
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

export default SideBar;
