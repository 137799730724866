import {
  IconButton,
  Menu,
  TableCell,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BASEURL } from "../../../services/api";
import tokenService from "../../../services/tokenService";
import { base64ToArrayBuffer, downloadFile } from "../../../utils/baseArray";
import { reduceWord } from "../../../utils/reduceWord";
import { EditPayment } from "./edit-payment";
import { RemovePayment } from "./remove-payment";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    letterSpacing: "0.1px",
    color: "#878aa0",
    borderBottom: "1px solid #f1f1f5",
    paddingTop: "0",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  userTitle: {
    fontWeight: 700,
    color: "#212b36",
    lineHeight: "36px",
    fontSize: 24,
    marginBottom: "1.2em",
  },
  bigCard: {
    padding: "25px 30px",
    minHeight: "100%",
    background: "#ffffff",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "16px",
    zIndex: 0,
  },
  iconBtn: {
    padding: "3px",
  },
  smalMenu: {
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "8px",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",

    outline: "px",
    borderColor: "#212b36",
  },
  menuItem: {
    display: "flex",
    justifyContent: "space-between",
    color: "#212b36",
  },
  iconN: {
    color: "#637381",
    marginRight: 20,
  },
  menuText: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#637381",
  },
  moreIcons: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export const PaymentBody = ({ payment }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [imgLoading, setImgLoading] = useState(false);

  const classes = useStyles();
  const token = tokenService.getToken();
  const user = tokenService.getUser();
  const { role } = user;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadImage = () => {
    let newData = {
      dateOfTransaction: payment?.dateOfTransaction,
      evidence: payment?.evidence,
      evidenceType: payment?.evidenceType,
      narration: payment?.narration,
      paymentId: payment?.id,
      paymentTypeId: payment?.paymentType?.id,
      tenantId: payment?.tenant?.id,
    };
    setImgLoading(true);
    axios
      .post(`${BASEURL}/payment/view`, newData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": undefined,
        },
      })

      .then((res) => {
        let imgToArray = base64ToArrayBuffer(res.data.data.byteArray);

        downloadFile(imgToArray, res.data.data.fileName);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setImgLoading(false);
      });
  };
  return (
    <>
      <TableCell>
        {payment?.paymentType.paymentType || "Not Available"}
      </TableCell>
      <TableCell>{moment(payment?.dateOfTransaction).format("L")}</TableCell>
      <TableCell>{payment?.evidence || "Not Available"}</TableCell>
      <TableCell>{payment?.evidenceType || "Not Available"}</TableCell>
      <TableCell>{payment?.narration || "Not Available"}</TableCell>
      <TableCell>{payment?.tenant?.fullName || "Not Available"}</TableCell>
      <Tooltip title="Click to download file" arrow>
        <TableCell>
          <Link
            to="/payments"
            onClick={() => downloadImage()}
            style={{ cursor: "pointer" }}
          >
            {imgLoading
              ? "Downloading file..."
              : reduceWord(payment?.docName) || "Not Available"}
          </Link>
        </TableCell>
      </Tooltip>
      <TableCell className={classes.moreIcons}>
        {role === "ADMIN" && (
          <IconButton
            className={classes.iconBtn}
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVert />
          </IconButton>
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.smalMenu}
          elevation={0}
        >
          <RemovePayment payment={payment.id} handleCloseMenu={handleClose} />
          <EditPayment payment={payment} handleCloseMenu={handleClose} />
        </Menu>
      </TableCell>
    </>
  );
};
