import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import SideBar from "../../components/sidebar";
import { BusinessType } from "../../pages/business-type";
import { Dashboard } from "../../pages/dashboard";
import { Documentation } from "../../pages/documentation";
import { DocumentType } from "../../pages/documentation/doc-types";
import { Documents } from "../../pages/documentation/documents";
import { Login } from "../../pages/login";
import { ResetPassword } from "../../pages/login/reset-password";
import { SetPassword } from "../../pages/login/set-password";
import { Maintenance } from "../../pages/maintenance";
import { MaintenanceStatus } from "../../pages/maintenance-status";
import { MaintenanceTypes } from "../../pages/maintenance-type";
import { NotFound } from "../../pages/not-found";
import { PaymentTypes } from "../../pages/payment-type";
import { Payments } from "../../pages/payments";
import { Rent } from "../../pages/rents";
import { Roles } from "../../pages/roles";
import { Shops } from "../../pages/shop";
import { ViewShop } from "../../pages/shop/viewShop";
import { Tenants } from "../../pages/tenants";
import { Users } from "../../pages/users";

import AuthGuard from "../../utils/AuthGuard";

export const AppNavigation = withRouter(({ location }) => {
  return (
    <>
      {location.pathname !== "/login" &&
        location.pathname !== "/set-password" &&
        location.pathname !== "/reset-password" &&
        location.pathname !== "/404" &&
        localStorage.getItem("token") && (
          <>
            <SideBar />
          </>
        )}
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/set-password" component={SetPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <AuthGuard exact path="/" component={Dashboard} />
        <AuthGuard exact path="/shops" component={Shops} />
        <AuthGuard path="/shops/:id" component={ViewShop} />
        <AuthGuard path="/users" component={Users} />
        <AuthGuard path="/maintenances" component={Maintenance} />
        <AuthGuard path="/tenants" component={Tenants} />
        <AuthGuard path="/rents" component={Rent} />
        <AuthGuard exact path="/documentations" component={Documentation} />
        <AuthGuard path="/documentations/:id" component={Documents} />
        <AuthGuard path="/document-types" component={DocumentType} />
        <AuthGuard path="/business-types" component={BusinessType} />
        <AuthGuard path="/maintenance-types" component={MaintenanceTypes} />
        <AuthGuard path="/maintenance-status" component={MaintenanceStatus} />
        <AuthGuard path="/payment-types" component={PaymentTypes} />
        <AuthGuard path="/payments" component={Payments} />
        <AuthGuard path="/roles" component={Roles} />
        <Route path="/404" component={NotFound} />
        <Redirect from="*" to="/404" />
      </Switch>
    </>
  );
});
