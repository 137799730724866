import {
  TextField,
  makeStyles,
  IconButton,
  Grid,
  Tooltip,
  Button,
} from "@material-ui/core";
import React from "react";
import { useFieldArray } from "react-hook-form";
import { Delete } from "@material-ui/icons";
import { NestedfieldArray } from "./nestedfield-array";

const useStyles = makeStyles((theme) => ({
  input: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(2, 89, 45)",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0, 171, 85)",
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
  },
  btn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    outline: " 0px",
    border: " 2px solid",
    margin: "10px 0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontWeight: 400,
    lineHeight: 1.71429,
    fontSize: "0.7rem",
    textTransform: "capitalize",
    minWidth: "64px",
    padding: "8px 17px",
    borderRadius: "8px",
    color: "rgb(0,0,0)",
    backgroundColor: "#fff",
    borderColor: "rgb(0, 171, 85)",
    height: "30px",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "rgb(0, 171, 85)",
      color: "rgb(255, 255, 255)",
    },
  },
}));

export const FieldArray = ({ control, register }) => {
  const classes = useStyles();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "facilities",
  });

  return (
    <div>
      <Button
        size="small"
        className={classes.btn}
        onClick={() => {
          append({ facilityType: "", numberOfFacility: "" });
        }}
      >
        Add Facility
      </Button>

      {fields.map((item, index) => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={5} sm={5}>
                <div>
                  <label>Facility Type</label>
                  <TextField
                    name="facilityType"
                    variant="outlined"
                    fullWidth
                    key={item.id}
                    className={classes.input}
                    required
                    defaultValue={item.facilityType}
                    type="text"
                    {...register(`facilities.${index}.facilityType`)}
                    style={{
                      marginRight: "10px",
                      marginTop: 10,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={5} sm={5}>
                <div>
                  <label>Number of Facilities</label>
                  <TextField
                    name="numberOfFacility"
                    variant="outlined"
                    fullWidth
                    className={classes.input}
                    required
                    key={item.id}
                    defaultValue={item.numberOfFacility}
                    {...register(`facilities.${index}.numberOfFacility`, {
                      valueAsNumber: true,
                    })}
                    style={{
                      marginRight: "10px",
                      marginTop: 10,
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={1} sm={1} className={classes.btnContainer}>
                <Tooltip title="Click to remove this field" arrow>
                  <IconButton onClick={() => remove(index)}>
                    <Delete color="secondary" />
                  </IconButton>
                </Tooltip>
              </Grid>

              <NestedfieldArray nestIndex={index} {...{ control, register }} />
            </Grid>
          </>
        );
      })}
    </div>
  );
};
