import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Typography,
  makeStyles,
  CssBaseline,
  Container,
  Grid,
  InputAdornment,
  IconButton,
  Divider,
  FormControl,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import svgIcon from "../../assets/building.svg";
import axios from "axios";
import { useForm } from "react-hook-form";
import tokenService from "../../services/tokenService";
import { BASEURL } from "../../services/api";
import { ForgotPassword } from "./forgot-password";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#fff",
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
    textAlign: "left",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error: {
    color: "rgb(235, 54, 54)",
  },
  bigCard: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "25px 30px",
    background: "#ffffff",
    boxShadow: "0px 14px 60px rgba(0,0,0,0.06)",
    borderRadius: "9px",
    transition: "all 0.4s ease-in-out 0s",
  },
  btn: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    boxSizing: "border-box",
    outline: " 0px",
    border: " 0px",
    margin: " 0px",
    cursor: "pointer",
    userSelect: "none",
    verticalAlign: "middle",
    appearance: "none",
    textDecoration: "none",
    fontWeight: 700,
    lineHeight: 1.71429,
    fontSize: "0.9375rem",
    textTransform: "capitalize",
    minWidth: "64px",
    padding: "8px 22px",
    borderRadius: "8px",
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(0, 171, 85)",
    width: "100%",
    boxShadow: "rgb(0 171 85 / 24%) 0px 8px 16px 0px",
    height: "48px",
    marginTop: "1.3em",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      backgroundColor: "rgb(2, 89, 45)",
    },
  },
  imgCover: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(33, 43, 54)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    height: "90%",
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    borderRadius: "16px",
    position: "relative",
    zIndex: 0,
    width: "100%",
    maxWidth: "464px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "82px 0px 76px 16px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  img: {
    width: "70%",
  },
  title: {
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "48px",
    color: "#212b36",
    marginBottom: "1.2em",
  },
  subtitle: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "48px",
    color: "#212b36",
    textAlign: "center",
  },
  input: {
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(2, 89, 45)",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(0, 171, 85)",
    },
  },
}));

export const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const {
    location: { state },
  } = props;

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${BASEURL}/auth/login`, data);
      tokenService.setToken(res.data.data?.data);
      if (state?.from) {
        window.location.replace(state?.from?.pathname);
      }
      // else go to home
      else {
        window.location.replace("/");
      }
    } catch (error) {
      setIsError(true);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setIsError(false);
      }, 2000);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className={classes.root}>
        <div className={classes.imgCover}>
          <Typography className={classes.title}>Hi, Welcome Back</Typography>
          <img src={svgIcon} alt="" className={classes.img} />
        </div>
        <Container maxWidth="sm">
          <CssBaseline />

          <div className={classes.paper}>
            <Typography
              component="h4"
              variant="subtitle1"
              className={classes.subtitle}
            >
              Sign in to Nana Plaza
            </Typography>

            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      name="username"
                      type="text"
                      fullWidth
                      variant="outlined"
                      className={classes.input}
                      required
                      id="outlined-adornment-weight"
                      error={isError}
                      {...register("username")}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        "aria-label": "username",
                      }}
                      labelWidth={0}
                      placeholder="Email"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      name="password"
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      error={isError}
                      required
                      variant="outlined"
                      className={classes.input}
                      placeholder="Password"
                      {...register("password")}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      labelWidth={0}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Button fullWidth className={classes.btn} type="submit">
                {isLoading ? (
                  <CircularProgress style={{ color: "#fff" }} size={25} />
                ) : (
                  "Sign In"
                )}
              </Button>
            </form>
            <Divider />
            <ForgotPassword />
          </div>
        </Container>
      </div>
    </>
  );
};
