import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Tooltip,
  CircularProgress,
  Typography,
  makeStyles,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Delete } from "@material-ui/icons";
import tokenService from "../../../../services/tokenService";
import { BASEURL } from "../../../../services/api";

const useStyles = makeStyles((theme) => ({
  iconN: {
    color: "#637381",
    marginRight: 10,
  },
  menuText: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#637381",
    textDecoration: "none",
  },
  menuItem: {
    // display: "flex",
    // justifyContent: "space-between",
    // color: "#212b36",
  },
}));
export const RemoveDocument = ({ doc, handleCloseMenu, fetchFiles }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const token = tokenService.getToken();
  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
    handleCloseMenu();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeDoc = () => {
    setLoading(true);
    axios
      .delete(`${BASEURL}/document/delete`, {
        params: { documentId: doc.id, tenantId: doc.tenant.id },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);

        toast.success("Document deleted", {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchFiles();
        setOpen(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <ToastContainer />
      <Tooltip title="Click to remove this document" arrow>
        <MenuItem onClick={handleClickOpen} className={classes.menuItem}>
          <Delete className={classes.iconN} />
          <Typography className={classes.menuText}>Delete</Typography>
        </MenuItem>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        maxWidth="sm"
        onClose={handleClose}
        fullWidth
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delete document"}
        </DialogTitle>

        <DialogContent>
          Are you sure you want to remove this document?
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={removeDoc}
            disabled={loading}
            color="secondary"
            autoFocus
            variant="contained"
          >
            {loading ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
