import React, { useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";

import { AddShop } from "./components/add-shop";

import { useAppContext } from "../../services/data.context";

import SearchBox from "../../components/searchbox";
import { ToastContainer } from "react-toastify";
import { Shop } from "./components/shop";
import tokenService from "../../services/tokenService";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },

  shopTitle: {
    fontWeight: 700,
    color: "#212b36",
    lineHeight: "36px",
    fontSize: 24,
    marginBottom: "1.2em",
  },
}));

export const Shops = () => {
  const classes = useStyles();

  const { shops } = useAppContext();

  const user = tokenService.getUser();
  const { role } = user;
  const [searchField, setSearchField] = useState("");

  const searchChange = (e) => {
    setSearchField(e.target.value);
  };

  function search(rows) {
    const columns = rows[0] && Object.keys(rows[0]);
    return rows.filter((row) =>
      columns.some((column) =>
        row[column].toString().toLowerCase().includes(searchField.toLowerCase())
      )
    );
  }

  return (
    <div className="content">
      <ToastContainer />
      <div className={classes.header}>
        <Typography className={classes.shopTitle} variant="h3">
          Shops
        </Typography>
        {role === "ADMIN" && <AddShop />}
      </div>
      <SearchBox searchChange={searchChange} place="Search by anything" />
      <Grid container spacing={4}>
        {search(shops).map((dt, index) => (
          <Shop dt={dt} index={index} />
        ))}
      </Grid>
      <div style={{ marginBottom: "20px" }}></div>
    </div>
  );
};
